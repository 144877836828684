var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "TheMainPage",
    [
      _c("TheHeader"),
      _c(
        "div",
        { staticClass: "flex flex-wrap" },
        [
          _c("TheSideNavigation"),
          _c(
            "div",
            { staticClass: "limiter-no-padding scrollbar" },
            [
              _vm.selectedMethod == ""
                ? _c("TheTitle", { attrs: { backIcon: "", title: "Back" } })
                : _c(
                    "div",
                    {
                      staticClass: "link pointer dim mb2 mv3 items-center flex",
                      on: {
                        click: function ($event) {
                          _vm.selectedMethod = ""
                        },
                      },
                    },
                    [
                      _c(
                        "i",
                        {
                          staticClass: "material-icons f3 light-gray mr3 v-btm",
                        },
                        [_vm._v("arrow_back")]
                      ),
                      _c("span", { staticClass: "f5 light-gray fw1 mr4" }, [
                        _vm._v("Back"),
                      ]),
                    ]
                  ),
              _vm.selectedMethod == ""
                ? _c(
                    "div",
                    {
                      staticClass:
                        "flex 100vh flex-column mt4 pb4 justify-center items-center silver pa3 tc bg-adori-light-gray ba b--adori-gray br2 br-bottom",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "light-gray ph3 pv4 f4 db b-color" },
                        [_vm._v("How would you like to create your voice?")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-50 relative ba ma2 b--adori-gray br3 pa3 white mh-150 pointer bg-adori-medium-gray noselect card-b",
                          on: {
                            click: function ($event) {
                              return _vm.handleMethod("RECORD")
                            },
                          },
                        },
                        [
                          _c(
                            "i",
                            {
                              staticClass:
                                "material-icons light-gray f2 v-mid pa1",
                            },
                            [_vm._v(" mic ")]
                          ),
                          _c("div", { staticClass: "word-wrap pt2 b" }, [
                            _vm._v("Record My voice"),
                          ]),
                          _c("div", { staticClass: "f6 gray pt3 mb2" }, [
                            _vm._v(
                              "Record your voice right here on the website"
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-50 relative ba ma2 b--adori-gray br3 pa3 white mh-150 pointer bg-adori-medium-gray noselect card-b",
                          on: {
                            click: function ($event) {
                              return _vm.handleMethod("UPLOAD")
                            },
                          },
                        },
                        [
                          _c(
                            "i",
                            {
                              staticClass:
                                "material-icons light-gray f2 v-mid pa1",
                            },
                            [_vm._v(" cloud_upload ")]
                          ),
                          _c("div", { staticClass: "word-wrap pt2 b" }, [
                            _vm._v("Upload Recording"),
                          ]),
                          _c("div", { staticClass: "f6 gray pt3 mb2" }, [
                            _vm._v("Upload your own recordings of your voice"),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-50 mb4 relative ba ma2 b--adori-gray br3 pa3 white mh-150 pointer bg-adori-medium-gray noselect card-b",
                          on: {
                            click: function ($event) {
                              return _vm.handleMethod("IMPORT")
                            },
                          },
                        },
                        [
                          _c(
                            "i",
                            {
                              staticClass:
                                "material-icons light-gray f2 v-mid pa1",
                            },
                            [_vm._v(" system_update_alt ")]
                          ),
                          _c("div", { staticClass: "word-wrap pt2 b" }, [
                            _vm._v("Import Voice from podcast"),
                          ]),
                          _c("div", { staticClass: "f6 gray pt3 mb2" }, [
                            _vm._v(
                              "\n            Import voice from existing podcast. Please note that podcast should have one speaker only\n          "
                            ),
                          ]),
                        ]
                      ),
                    ]
                  )
                : _c(
                    "div",
                    { staticClass: "flex 100vh flex-column mt4 pb4 silver" },
                    [
                      _vm.selectedMethod == "RECORD"
                        ? _c("div", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "bg-adori-light-gray ba b--adori-gray br2 br-bottom pa3 tc",
                              },
                              [
                                _vm._v(
                                  "\n            Speak clearly with a good microphone and no background noise. We need a minimum of 25 samples to get\n            started.\n          "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "silver flex justify-center f6 mt4",
                              },
                              [
                                _vm._v(
                                  "You have recorded 0 / 25 samples so far (4.00%)"
                                ),
                              ]
                            ),
                            _c("div", { staticClass: "progress-wrapper mt2" }, [
                              _c("div", { staticClass: "progress" }, [
                                _c("div", {
                                  staticClass: "progress-bar",
                                  style: "width:" + 10 + "%",
                                }),
                              ]),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "bg-adori-light-gray pa4 flex flex-column items-center sample-sentence-box",
                              },
                              [
                                _c("div", { staticClass: "f6" }, [
                                  _vm._v("SAY THE SENTENCE BELOW"),
                                ]),
                                _c("div", { staticClass: "f4 white mt3" }, [
                                  _vm._v(
                                    "I consent to having my voice cloned by Adori."
                                  ),
                                ]),
                                _c("div", { staticClass: "waveform" }, [
                                  _c("canvas", {
                                    ref: "canvas",
                                    attrs: { width: "800", height: "50" },
                                  }),
                                ]),
                                _c("div", { staticClass: "recorder_wrapper" }, [
                                  _c("div", { staticClass: "recorder" }, [
                                    _c("button", {
                                      staticClass: "record_btn",
                                      class: { recording: _vm.isRecording },
                                      on: { click: _vm.toggleRecording },
                                    }),
                                    _c("div", { staticClass: "mt1" }, [
                                      _vm._v(_vm._s(_vm.message)),
                                    ]),
                                    _c(
                                      "a",
                                      {
                                        ref: "downloadRef",
                                        staticClass: "dn",
                                        attrs: { download: "" },
                                      },
                                      [_vm._v("Download Audio")]
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "bg-adori-medium-gray record-box flex justify-between ph2 mb4",
                              },
                              [
                                _vm.audioSrc
                                  ? _c(
                                      "button",
                                      {
                                        staticClass:
                                          "white bg-adori-red bn br3 flex justify-center items-center ph3 pv2 ma2",
                                      },
                                      [
                                        _vm._v(
                                          "\n              Submit\n            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.audioSrc
                                  ? _c("audio", {
                                      staticClass: "a-player mb2",
                                      attrs: {
                                        src: _vm.audioSrc,
                                        controls: "",
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm.selectedMethod == "UPLOAD"
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "bg-adori-light-gray ba b--adori-gray br2 br-bottom pa3 tc",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "pb gray f6 flex items-center" },
                                [_vm._v("Voice Name")]
                              ),
                              _c("div", { staticClass: "relative" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.voiceName,
                                      expression: "voiceName",
                                    },
                                  ],
                                  staticClass:
                                    "db bg-adori-light-gray mb4 b--gray ba br2 ph3 pv3 gray f6 white w-100 mt1",
                                  attrs: { type: "text", placeholder: "" },
                                  domProps: { value: _vm.voiceName },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.voiceName = $event.target.value
                                    },
                                  },
                                }),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "w-100 br2 pv4 flex flex-column items-center justify-center custom-h-drop b--light-gray b--dashed pointer",
                                },
                                [
                                  _c(
                                    "i",
                                    {
                                      staticClass:
                                        "material-icons f-headline silver mb2",
                                    },
                                    [_vm._v("cloud_upload")]
                                  ),
                                  _c("div", { staticClass: "f4 mb2 silver" }, [
                                    _vm._v(
                                      "Click to select file or drag it here"
                                    ),
                                  ]),
                                  0
                                    ? _c("span", { staticClass: "mt2 gray" }, [
                                        _vm._v("selectedFileName "),
                                      ])
                                    : _vm._e(),
                                  0
                                    ? _c("span", { staticClass: "f6 gray" }, [
                                        _vm._v("(selectedFileSize )"),
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex flex-column items-center justify-center",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "f6 silver mt1" },
                                        [_vm._v("Supports: mp3, wav")]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "f6 silver mt1" },
                                        [_vm._v("Max Size: 200MB")]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "flex justify-between mt4 mb4" },
                                [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.gender,
                                          expression: "gender",
                                        },
                                      ],
                                      staticClass:
                                        "w-50 mr2 bg-adori-light-gray white f6 br2 h2 ba b--gray mt1",
                                      on: {
                                        change: [
                                          function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.gender = $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          },
                                          function ($event) {},
                                        ],
                                      },
                                    },
                                    _vm._l(
                                      ["Male", "Female"],
                                      function (item, i) {
                                        return _c(
                                          "option",
                                          { key: i, domProps: { value: item } },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(item) +
                                                "\n              "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.age,
                                          expression: "age",
                                        },
                                      ],
                                      staticClass:
                                        "w-50 ml2 ph2 bg-adori-light-gray white f6 br2 h2 ba b--gray mt1",
                                      on: {
                                        change: [
                                          function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.age = $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          },
                                          function ($event) {},
                                        ],
                                      },
                                    },
                                    _vm._l(
                                      ["Child", "Young", "Middle Age", "Old"],
                                      function (item, i) {
                                        return _c(
                                          "option",
                                          { key: i, domProps: { value: item } },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(item) +
                                                "\n              "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "light-gray f4 db b-color flex items-center justify-center",
                                },
                                [
                                  _c("BaseButtonRed", {
                                    attrs: { text: "Create Voice" },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.selectedMethod == "IMPORT"
                        ? _c("div", [_vm._v("IMPORT")])
                        : _vm._e(),
                    ]
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }