






























































































































import { Component, Vue } from 'vue-property-decorator'
import TheMainPage from '@/components/Common/TheMainPage.vue'
import TheHeader from '@/components/Common/TheHeader.vue'
import TheSideNavigation from '@/components/Common/TheSideNavigation.vue'
import TheTitle from '@/components/Common/TheTitle.vue'

@Component({
  components: {
    TheMainPage,
    TheHeader,
    TheSideNavigation,
    TheTitle,
  },
})
export default class ViewAiVoiceClone extends Vue {
  selectedMethod = ''
  voiceName = ''
  gender = 'Male'
  age = 'Young'

  // Audio Recording
  message: string = 'Press to start recording'
  isRecording: boolean = false
  mediaRecorder: MediaRecorder | null = null
  chunks: Blob[] = []
  audio: HTMLAudioElement = new Audio()
  audioSrc: string | null = null
  time: number = 0
  lang = {
    mic_error: 'Error accessing the microphone',
    press_to_start: 'Press to start recording',
    recording: 'Recording',
    default: '',
  }

  //sinewave
  canvasRef: any = null
  canvasContext!: any
  audioContext: AudioContext | null = null
  analyser: AnalyserNode | null = null
  canvasVisual: number | null = null

  mounted() {
    if (navigator.mediaDevices === undefined) {
      ;(navigator as any).mediaDevices = {}
    }

    if (navigator.mediaDevices.getUserMedia === undefined) {
      navigator.mediaDevices.getUserMedia = function (constraints) {
        const getUserMedia = (navigator as any).webkitGetUserMedia || (navigator as any).mozGetUserMedia
        if (!getUserMedia) {
          return Promise.reject(new Error('getUserMedia is not implemented in this browser'))
        }

        return new Promise(function (resolve, reject) {
          getUserMedia.call(navigator, constraints, resolve, reject)
        })
      }
    }

    if (!navigator.mediaDevices.getUserMedia) {
      this.message = this.lang.mic_error
    }
  }

  toggleRecording() {
    if (!this.isRecording) {
      this.startRecording()
    } else {
      this.stopRecording()
    }
  }

  parseTime(sec: number): string {
    const h = Math.floor(sec / 3600)
    const m = Math.floor(sec / 60)
    sec = sec - h * 3600 - m * 60

    const hours = h === 0 ? '' : h + ':'
    const seconds = sec < 10 ? '0' + sec : sec.toString()

    return hours + m + ':' + seconds
  }

  startRecording() {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream: MediaStream) => {
        this.canvasRef = this.$refs.canvas
        this.canvasContext = this.canvasRef.getContext('2d')
        this.audioContext = new AudioContext()
        this.analyser = this.audioContext.createAnalyser()
        const source = this.audioContext.createMediaStreamSource(stream)

        // Sinewave visualization
        if (this.analyser) {
          const WIDTH = this.canvasRef.width
          const HEIGHT = this.canvasRef.height
          this.analyser.fftSize = 2048
          const bufferLength = this.analyser.fftSize
          const dataArray = new Uint8Array(bufferLength)

          const draw = () => {
            this.canvasVisual = requestAnimationFrame(draw)

            this.analyser?.getByteTimeDomainData(dataArray)

            this.canvasContext.clearRect(0, 0, WIDTH, HEIGHT)
            this.canvasContext.fillStyle = '#313131'
            this.canvasContext.fillRect(0, 0, WIDTH, HEIGHT)

            this.canvasContext.lineWidth = 2
            this.canvasContext.strokeStyle = '#DE1A23'
            this.canvasContext.beginPath()

            const sliceWidth = (WIDTH * 1.0) / bufferLength
            let x = 0

            for (let i = 0; i < bufferLength; i++) {
              const v = dataArray[i] / 128.0
              const y = (v * HEIGHT) / 2

              if (i === 0) {
                this.canvasContext.moveTo(x, y)
              } else {
                this.canvasContext.lineTo(x, y)
              }

              x += sliceWidth
            }

            this.canvasContext.lineTo(WIDTH, HEIGHT / 2)
            this.canvasContext.stroke()
          }

          source.connect(this.analyser)

          draw()
        }

        this.mediaRecorder = new MediaRecorder(stream)
        this.mediaRecorder.start()

        this.isRecording = true
        this.message = this.lang.recording

        if (navigator.vibrate) navigator.vibrate(150)

        this.time = Math.ceil(new Date().getTime() / 1000)

        this.mediaRecorder.ondataavailable = (event: BlobEvent) => {
          this.chunks.push(event.data)
        }

        this.mediaRecorder.onstop = () => {
          stream.getTracks().forEach((track) => track.stop())

          const type = { type: 'audio/ogg,codecs=opus' }
          const blob = new Blob(this.chunks, type)
          this.audioSrc = URL.createObjectURL(blob)

          this.audio.src = this.audioSrc

          this.chunks = []
        }
      })
      .catch((error: Error) => {
        this.message = this.lang.mic_error
      })
  }

  stopRecording() {
    if (this.mediaRecorder) {
      this.mediaRecorder.stop()
    }
    if (this.canvasVisual) {
      cancelAnimationFrame(this.canvasVisual)
      this.canvasVisual = null
    }
    this.isRecording = false
    this.message = this.lang.default

    if (navigator.vibrate) navigator.vibrate([200, 100, 200])

    const now = Math.ceil(new Date().getTime() / 1000)
    const t = this.parseTime(now - this.time)
  }

  save() {
    if (this.audioSrc) {
      const link = this.$refs.downloadRef as HTMLAnchorElement
      link.download = 'record.ogg'
      link.href = this.audioSrc
      link.click()
    }
  }

  handleMethod(method: string) {
    this.selectedMethod = method
  }
}
